<template>
    <section>
        <modal-confirmar
        ref="modalInactivarChat"
        titulo="Inactivar chat"
        icon="activar-chat"
        mensaje="¿Desea inactivar el chat con el tendero?"
        @guardar="estadoChat(0)"
        >
            <div class="row mx-0 justify-center text-center f-15 mb-1">
                Él solo podrá enviar 3 mensajes máximo, hasta que reciba una respuesta no podrá un nuevo mensaje
            </div>
        </modal-confirmar>
        <modal-confirmar
        ref="modalActivarChat"
        titulo="Activar chat"
        icon="activar-chat"
        mensaje="¿Desea activar el chat con el tendero?"
        @guardar="estadoChat(1)"
        />
    </section>
</template>

<script>
export default {
    methods: {
        toggle(tipo){
            if (tipo){
                this.$refs.modalInactivarChat.toggle()
            }else{
                this.$refs.modalActivarChat.toggle()
            }
        },
        estadoChat(est){
            this.$emit('estadoChat', est)
        }
    }
}
</script>

<style lang="css" scoped>
</style>
